import React from "react";
import ReactPlayer from "react-player";

const VideoComponent = ({
  compoVideo,
  width,
  height,
  left,
  muted,
  controls,
}) => {
  return (
    <div className="video-player-container">
      <ReactPlayer
        url={compoVideo}
        width={width}
        height={height}
        muted={muted}
        loop
        playing={true}
        controls={controls}
        style={{ position: "relative", top: "5px", left: { left } }} // Corregido el valor de `left`
      />
    </div>
  );
};

// Valores por defecto
VideoComponent.defaultProps = {
  width: "230px",
  height: "250px",
  left: "-22px",
  muted: true,
  controls: false,
};

export default VideoComponent;
