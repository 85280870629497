/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import VideoComponent from "../common/VideoComponent"
import { Modal } from "reactstrap";

import "../../Styles/modules/_modalVersionSelector.scss";

export const ModalIntroVideo = ({
  isOpen,
  onClose,
}) => {


  return (
    <Modal
      isOpen={isOpen}
      className="transitionSelector preview modalVideo isOpen"
      size="xl"
    >
  
      <section>
        <div className="modal__container">
          <div className="row" style={{ marginBottom: "0px" }}>
            <div className="modal__title primary">Tutorial</div>
            <div className="modal__buttons__themes">
              <button
                type="button"
                className="close__button custom-button-theme cancel-button"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
          <div className="body__modal row">


            <div className="body__background">
              <table
                className="table row"
                style={{ overflowY: "hidden",  margin: "0 auto"  }}
              >
                <tbody className="table__body ">
                <div className={"react-tooltip-video"} > <VideoComponent controls={true} muted={false} width="1100px" height="auto" left="0px" compoVideo= "./sidevideos/FengShopifyTutorial.mp4"/></div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};
